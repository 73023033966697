import React, {useEffect, useRef} from 'react';
import './banner.css';
import Form from './components/form/Form';
import {useDispatch, useSelector} from 'react-redux';
import ShowThx from './components/showThx/showThx';

export default function Banner() {
  const dispatch = useDispatch();
  const main = useRef(null);
  const showThx = useSelector((state) => state.showThx);
  console.log(showThx);

  useEffect(() => {
    const onClick = (e) =>
      main.current.contains(e.target) ||
      (dispatch({type: 'SHOWTHX', payload: false}) &&
        dispatch({type: 'SHOWBANNER', payload: false}));
    document.body.classList.add('modal-open');

    const onKeydown = ({key}, KeyboardEvent) => {
      switch (key) {
        case 'Escape':
          dispatch({type: 'SHOWTHX', payload: false});
          dispatch({type: 'SHOWBANNER', payload: false});
          break;
      }
    };
    document.addEventListener('click', onClick);
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('keydown', onKeydown);
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      <div className="bannerBox">
        <div className={`banner ${showThx && 'bannerThx'}`} ref={main}>
          <Form />
          {showThx && <ShowThx />}
        </div>
      </div>
    </>
  );
}
